import React from "react";
import Layout from "../../components/Layout";

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="article">
          <div class="o-content-container">
            <h3 id="help-center">Help Center</h3>

            <ul>
              <li>
                <a href="/help/articles/basic-information/getting-started-with-tuskle">
                  Getting started with tuskle
                </a>
              </li>
              <li>
                Patient management
                <ul>
                  <li>Creating a new patient (In progress)</li>
                  <li>Updating an existing patient (In progress)</li>
                  <li>Deleting an existing patient (In progress)</li>
                </ul>
              </li>
            </ul>
          </div>
        </section>
      </Layout>
    );
  }
}
